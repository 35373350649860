import { SurveyPreviewerTypeEnum } from '@elendi/util-types'

export const ROUTE = {
  index: '/',
  user: {
    home: '/user/home',
    aiCompanion: '/user/ai-companion',
    aiConversation: (id: string) => `/user/ai-conversation/${encodeURI(id)}`,
    capabilities: '/user/capabilities',
    developmentPlan: '/user/development-plan',
    pulse: '/user/pulse',
    performanceInterview: '/user/performance-interview',
    profile: '/user/profile',
    feedbacks: '/user/feedbacks',
    peerSelectionFn: (id: string) => `/user/peer-selection/${encodeURI(id)}`,
    notificationCenter: '/notifications',
    iaReadyGlobalResults: '/generative-ai-ready/results',
    surveyModule: '/survey-module',
    surveyModuleResults: '/survey-module/results',
    surveyModuleIndividualResults: '/survey-module/individualResults',
    surveyEditor: {
      index: '/survey-editor',
      surveyFn: (id: string) => `/survey-editor/survey/${encodeURI(id)}`,
    },
  },
  learningPrograms: {
    index: `/learning-programs`,
    self: ({ moduleId }: { moduleId: string }) =>
      `/learning-programs/module/${encodeURI(moduleId)}`,
    aggregatedResults: ({
      learningProgramId,
      moduleId,
    }: {
      learningProgramId: string
      moduleId: string
    }) => `/learning-programs/results/${encodeURI(learningProgramId)}/${encodeURI(moduleId)}`,
    individualProfiles: ({
      learningProgramId,
      moduleId,
      email,
    }: {
      learningProgramId: string
      moduleId: string
      email: string
    }) =>
      `/learning-programs/individual-profiles/${encodeURI(learningProgramId)}/${encodeURI(moduleId)}/${encodeURI(email)}`,
  },
  manager: {
    home: '/manager/home',
    capabilities: '/manager/capabilities',
    strutcuralEngagement: '/manager/structural-engagement',
    individualProfile: '/manager/individual-profile',
    developmentPlan: '/manager/development-plan',
    performanceInterviewDashboard: '/manager/performance-interview',
    teamInfo: '/manager/team-info',
    individualProfileFn: (about: string) => `manager/individual-profile/${encodeURI(about)}`,
    splitters: '/engagement/splitters',
    pulse: '/manager/pulse',
    downloadAttachments: '/manager/objectives-attachments-dowload',
  },
  error: {
    accessDenied: '/403',
    notFound: '/404',
  },
  public: {
    futureOfWork: '/future-of-work',
    iaReady: '/generative-ai-ready',
    aiSurvey: '/future-ready',
  },
  demo: {
    surveyPreviewFn: (surveyId: string, type: SurveyPreviewerTypeEnum) =>
      `/demo/survey-preview/${surveyId}/${type}`,
  },
  auth: {
    login: '/login',
    signup: '/signup',
    emailVerification: '/email-verification',
    emailVerified: '/email-verified',
  },
  admin: {
    aiCompanionDemo: {
      index: '/admin/demo-ai-companion',
    },
    aiGuided: {
      table: '/aiguided',
      add: '/aiguided/add',
      demo: (id: string) => `/aiguided/demo?id=${id}`,
      edit: (id: string) => `/aiguided/edit?id=${id}`,
    },
    aiTools: {
      promptTesting: '/admin/ai-tools/prompt-testing',
    },
    capabilities: {
      index: '/admin/capabilities',
    },
    learnings: {
      index: '/admin/learnings',
    },
    metatheme: {
      index: '/admin/meta-themes',
    },
    questions: {
      index: '/admin/questions',
    },
    surveyResponses: {
      id: (surveyId: string) => `/admin/survey-responses/results/${surveyId}`,
    },
    survey: {
      create: '/admin/survey/create',
      edit: '/admin/survey/edit',
    },
    batchAdministration: {
      customLabels: '/admin/batches/edit-elbatch-custom-labels',
      editFilters: '/admin/batches/editFilters',
      aiSummaries: '/admin/batches/edit-ai-summaries',
      evolution: '/admin/batches/edit-el-evolution-mapping',
      grantAccess: '/admin/batches/edit-access-grants',
      grantAccessToAggregatedBatchData: '/admin/batches/edit-access-aggregated-batch-data',
    },
    wip: {
      aipt: '/wip/aipt',
    },
  },
} as const

export const PAGE_QUERIES = {
  individualProfile: {
    selectedTab: 'tab',
    batchId: 'batchId',
  },
}
